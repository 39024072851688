'use client';

import { Button } from '@mantine/core';
import { useRouter } from 'next/navigation';

export default function BackButton() {
  const router = useRouter();

  return (
    <Button radius="xl" size="lg" onClick={() => router.push('/')}>
      Voltar para o começo
    </Button>
  );
}
